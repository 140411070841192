@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-Regular-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-Regular-Web.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-RegularItalic-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-RegularItalic-Web.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-Medium-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-Medium-Web.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-MediumItalic-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-MediumItalic-Web.woff') format('woff');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-Bold-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-Bold-Web.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-BoldItalic-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-BoldItalic-Web.woff') format('woff');
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-Black-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-Black-Web.woff') format('woff');
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-BlackItalic-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-BlackItalic-Web.woff') format('woff');
  font-style: italic;
  font-weight: 900;
}
@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-Light-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-Light-Web.woff') format('woff');
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-LightItalic-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-LightItalic-Web.woff') format('woff');
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-Thin-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-Thin-Web.woff') format('woff');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'MunichRE';
  src: url('fonts/MunichReSans2017-ThinItalic-Web.woff2') format('woff2');
  src: url('fonts/MunichReSans2017-ThinItalic-Web.woff') format('woff');
  font-style: italic;
  font-weight: 100;
}

.xds\:d-none {
  display: none; /* TODO make this !important so we can be certain it overrides e.g. display: flex or grid or whatever */
}

.xds\:d-none {
  display: none;
}

.xds\:highlight-match {
  font-style: normal;
  background: var(--xds-color-primary-interaction-20);
  padding: 0;
}

/* use "xds:dropdown-item-content-stretch" for content of xds-dropdown-item if the content should stretch to fit the whole dropdown item, e.g. for links */
xds-dropdown-item:has(.xds\:dropdown-item-content-stretch) {
  position: relative;
}

xds-dropdown-item > .xds\:dropdown-item-content-stretch::before {
  content: '';
  position: absolute;
  inset: 0;
}

:root {
  --xds-font-stack: 'MunichRE', Arial, sans-serif;

  --xds-spacing-00: 0;
  --xds-spacing-01: 0.125rem;
  --xds-spacing-02: 0.25rem;
  --xds-spacing-03: 0.5rem;
  --xds-spacing-04: 0.75rem;
  --xds-spacing-05: 1rem;
  --xds-spacing-06: 1.25rem;
  --xds-spacing-07: 1.5rem;
  --xds-spacing-08: 1.75rem;
  --xds-spacing-09: 2rem;
  --xds-spacing-10: 2.5rem;
  --xds-spacing-11: 3rem;
  --xds-spacing-12: 3.5rem;
  --xds-spacing-13: 4rem;
  --xds-spacing-14: 4.5rem;

  --xds-layout-01: 0.5rem;
  --xds-layout-02: 1rem;
  --xds-layout-03: 1.5rem;
  --xds-layout-04: 2rem;
  --xds-layout-05: 2.5rem;
  --xds-layout-06: 3rem;
  --xds-layout-07: 4rem;
  --xds-layout-08: 5rem;
  --xds-layout-09: 6rem;

  --xds-radii-0: 0;
  --xds-radii-1: 0.25rem;
  --xds-radii-2: 0.5rem;

  --xds-border-width-0: 0;
  --xds-border-width-1: 0.0625rem;
  --xds-border-width-2: 0.125rem;
  --xds-border-width-3: 0.1875rem;
  --xds-border-width-4: 0.25rem;

  --xds-shadow-0: none;

  --xds-time-0: 0;
  --xds-time-100: 100ms;
  --xds-time-200: 200ms;
  --xds-time-300: 300ms;
  --xds-time-500: 500ms;
  --xds-time-1000: 1000ms;
}

:root {
  /** Typography Scale with regular font **/
  /**
  * Max Scale
  **/
  --xds-title-h1-reg-max: normal normal 400 1.875rem/2.375rem var(--xds-font-stack);
  --xds-title-h2-reg-max: normal normal 400 1.625rem/2.125rem var(--xds-font-stack);
  --xds-title-h3-reg-max: normal normal 400 1.375rem/1.625rem var(--xds-font-stack);
  --xds-title-h4-reg-max: normal normal 400 1.25rem/1.5rem var(--xds-font-stack);

  --xds-body-l-reg-max: var(--xds-body-l-reg);
  --xds-body-m-reg-max: var(--xds-body-m-reg);
  --xds-body-s-reg-max: var(--xds-body-s-reg);

  --xds-caption-m-reg-max: var(--xds-caption-m-reg);
  --xds-caption-s-reg-max: var(--xds-caption-s-reg);

  --xds-label-reg-max: var(--xds-label-reg);

  /**
  * M - XL Scale
  **/
  --xds-title-h1-reg: normal normal 400 1.75rem/2rem var(--xds-font-stack);
  --xds-title-h2-reg: normal normal 400 1.5rem/1.75rem var(--xds-font-stack);
  --xds-title-h3-reg: normal normal 400 1.25rem/1.5rem var(--xds-font-stack);
  --xds-title-h4-reg: normal normal 400 1.125rem/1.5rem var(--xds-font-stack);

  --xds-body-l-reg: normal normal 400 1.125rem/1.625rem var(--xds-font-stack);
  --xds-body-m-reg: normal normal 400 1rem/1.375rem var(--xds-font-stack);
  --xds-body-s-reg: normal normal 400 0.875rem/1.25rem var(--xds-font-stack);

  --xds-caption-m-reg: normal normal 400 0.75rem/1.125rem var(--xds-font-stack);
  --xds-caption-s-reg: normal normal 400 0.7rem/1.0625rem var(--xds-font-stack);

  --xds-label-reg: normal normal 400 0.625rem/1.125rem var(--xds-font-stack);

  /**
  * S Scale
  **/
  --xds-title-h1-reg-s: normal normal 400 1.625rem/1.875rem var(--xds-font-stack);
  --xds-title-h2-reg-s: normal normal 400 1.375rem/1.625rem var(--xds-font-stack);
  --xds-title-h3-reg-s: var(--xds-title-h3-reg);
  --xds-title-h4-reg-s: var(--xds-title-h4-reg);

  --xds-body-l-reg-s: var(--xds-body-l-reg);
  --xds-body-m-reg-s: var(--xds-body-m-reg);
  --xds-body-s-reg-s: var(--xds-body-s-reg);

  --xds-caption-m-reg-s: var(--xds-caption-m-reg);
  --xds-caption-s-reg-s: var(--xds-caption-s-reg);

  --xds-label-reg-s: var(--xds-label-reg);

  /**
   * Typography scale with medium font;
   * */

  /**
  * Max Scale
  **/
  --xds-title-h1-med-max: normal normal 600 1.875rem/2.375rem var(--xds-font-stack);
  --xds-title-h2-med-max: normal normal 600 1.625rem/2.125rem var(--xds-font-stack);
  --xds-title-h3-med-max: normal normal 600 1.375rem/1.625rem var(--xds-font-stack);
  --xds-title-h4-med-max: normal normal 600 1.25rem/1.5rem var(--xds-font-stack);

  --xds-body-l-med-max: var(--xds-body-l-med);
  --xds-body-m-med-max: var(--xds-body-m-med);
  --xds-body-s-med-max: var(--xds-body-s-med);

  --xds-caption-m-med-max: var(--xds-caption-m-med);
  --xds-caption-s-med-max: var(--xds-caption-s-med);

  --xds-label-med-max: var(--xds-label-med);

  /**
  * M - XL Scale
  **/
  --xds-title-h1-med: normal normal 600 1.75rem/2rem var(--xds-font-stack);
  --xds-title-h2-med: normal normal 600 1.5rem/1.75rem var(--xds-font-stack);
  --xds-title-h3-med: normal normal 600 1.25rem/1.5rem var(--xds-font-stack);
  --xds-title-h4-med: normal normal 600 1.125rem/1.5rem var(--xds-font-stack);

  --xds-body-l-med: normal normal 600 1.125rem/1.625rem var(--xds-font-stack);
  --xds-body-m-med: normal normal 600 1rem/1.375rem var(--xds-font-stack);
  --xds-body-s-med: normal normal 600 0.875rem/1.25rem var(--xds-font-stack);

  --xds-caption-m-med: normal normal 600 0.75rem/1.125rem var(--xds-font-stack);
  --xds-caption-s-med: normal normal 600 0.7rem/1.0625rem var(--xds-font-stack);

  --xds-label-med: normal normal 600 0.625rem/1.125rem var(--xds-font-stack);

  /**
  * S Scale
  **/
  --xds-title-h1-med-s: normal normal 600 1.625rem/1.875rem var(--xds-font-stack);
  --xds-title-h2-med-s: normal normal 600 1.375rem/1.625rem var(--xds-font-stack);
  --xds-title-h3-med-s: var(--xds-title-h3-med);
  --xds-title-h4-med-s: var(--xds-title-h4-med);

  --xds-body-l-med-s: var(--xds-body-l-med);
  --xds-body-m-med-s: var(--xds-body-m-med);
  --xds-body-s-med-s: var(--xds-body-s-med);

  --xds-caption-m-med-s: var(--xds-caption-m-med);
  --xds-caption-s-med-s: var(--xds-caption-s-med);

  --xds-label-med-s: var(--xds-label-med);
}

:root {
  /**
        PRIMARIES
    */
  --xds-color-0: hsl(206, 8%, 17%);
  --xds-color-1: hsl(206, 71%, 31%);
  --xds-color-2: hsl(213, 5%, 38%);
  --xds-color-3: hsl(210, 2%, 78%);
  --xds-color-4: hsl(0, 0%, 100%);
  --xds-color-5: hsla(206, 8%, 17%, 0.9);

  /**
        GREYS
    */
  --xds-color-grey-dark: var(--xds-color-0);
  --xds-color-grey-dark-alpha-4: hsla(206, 8%, 17%, 4%);
  --xds-color-grey-light: var(--xds-color-3);
  --xds-color-grey-1: hsl(210, 1%, 42%);
  --xds-color-grey-1-alpha-4: hsla(210, 1%, 42%, 4%);
  --xds-color-grey-2: hsl(210, 4%, 56%);
  --xds-color-grey-3: hsl(216, 4%, 74%);
  --xds-color-grey-4: hsl(180, 1%, 85%);
  --xds-color-grey-5: hsl(0, 0%, 90%);
  --xds-color-grey-6: hsl(0, 0%, 93%);
  --xds-color-grey-6-alpha-15: hsla(0, 0%, 93%, 15%);
  --xds-color-grey-7: hsl(0, 0%, 98%);

  /**
    CLEAR AND PURE
  */
  --xds-color-green: hsl(98, 63%, 41%);
  --xds-color-green-alpha-40: hsl(98, 63%, 41%, 0.4);
  --xds-color-green-alpha-20: hsl(98, 63%, 41%, 0.2);
  --xds-color-green-alpha-4: hsl(98, 63%, 41%, 0.04);
  --xds-color-green-dark: hsl(98, 63%, 31%);
  --xds-color-green-dark-alpha-20: hsl(98, 63%, 31%, 0.2);
  --xds-color-green-dark-alpha-4: hsl(98, 63%, 31%, 0.04);
  --xds-color-green-light: hsl(98, 44%, 62%);

  --xds-color-dark-green: hsl(157, 100%, 30%);
  --xds-color-dark-green-alpha-40: hsl(157, 100%, 30%, 0.4);
  --xds-color-dark-green-alpha-20: hsl(157, 100%, 30%, 0.2);
  --xds-color-dark-green-alpha-4: hsl(157, 100%, 30%, 0.04);
  --xds-color-dark-green-dark: hsl(157, 100%, 22%);
  --xds-color-dark-green-dark-alpha-20: hsl(157, 100%, 22%, 0.2);
  --xds-color-dark-green-dark-alpha-4: hsl(157, 100%, 22%, 0.04);
  --xds-color-dark-green-light: hsl(157, 42%, 54%);

  --xds-color-turquoise: hsl(175, 67%, 29%);
  --xds-color-turquoise-alpha-40: hsl(175, 67%, 29%, 0.4);
  --xds-color-turquoise-alpha-20: hsl(175, 67%, 29%, 0.2);
  --xds-color-turquoise-alpha-4: hsl(175, 67%, 29%, 0.04);
  --xds-color-turquoise-dark: hsl(175, 67%, 24%);
  --xds-color-turquoise-dark-alpha-20: hsl(175, 67%, 24%, 0.2);
  --xds-color-turquoise-dark-alpha-4: hsl(175, 67%, 24%, 0.04);
  --xds-color-turquoise-light: hsl(176, 51%, 69%);

  --xds-color-teal: hsl(188, 100%, 29%);
  --xds-color-teal-alpha-40: hsl(188, 100%, 29%, 0.4);
  --xds-color-teal-alpha-20: hsl(188, 100%, 29%, 0.2);
  --xds-color-teal-alpha-4: hsl(188, 100%, 29%, 0.04);
  --xds-color-teal-dark: hsl(188, 100%, 22%);
  --xds-color-teal-dark-alpha-20: hsl(188, 100%, 22%, 0.2);
  --xds-color-teal-dark-alpha-4: hsl(188, 100%, 22%, 0.04);
  --xds-color-teal-light: hsl(188, 40%, 57%);

  --xds-color-cyan: hsl(191, 100%, 43%);
  --xds-color-cyan-alpha-40: hsla(191, 100%, 43%, 0.4);
  --xds-color-cyan-alpha-50: hsla(191, 100%, 43%, 0.5);
  --xds-color-cyan-alpha-20: hsla(191, 100%, 43%, 0.2);
  --xds-color-cyan-alpha-4: hsla(191, 100%, 43%, 0.04);
  --xds-color-cyan-dark: hsl(191, 81%, 33%);
  --xds-color-cyan-dark-alpha-20: hsla(191, 81%, 33%, 0.2);
  --xds-color-cyan-dark-alpha-4: hsla(191, 81%, 33%, 0.04);
  --xds-color-cyan-light: hsl(191, 68%, 68%);

  --xds-color-pale-blue: hsl(211, 51%, 63%);
  --xds-color-pale-blue-alpha-40: hsl(211, 51%, 63%, 0.4);
  --xds-color-pale-blue-alpha-20: hsl(211, 51%, 63%, 0.2);
  --xds-color-pale-blue-alpha-4: hsl(211, 51%, 63%, 0.04);
  --xds-color-pale-blue-dark: hsl(211, 30%, 47%);
  --xds-color-pale-blue-dark-alpha-20: hsl(211, 30%, 47%, 0.2);
  --xds-color-pale-blue-dark-alpha-4: hsl(211, 30%, 47%, 0.04);
  --xds-color-pale-blue-light: hsl(210, 50%, 75%);

  --xds-color-dark-blue: hsl(209, 92%, 19%);
  --xds-color-dark-blue-alpha-40: hsl(209, 92%, 19%, 0.4);
  --xds-color-dark-blue-alpha-50: hsl(209, 92%, 19%, 0.5);
  --xds-color-dark-blue-alpha-20: hsl(209, 92%, 19%, 0.2);
  --xds-color-dark-blue-alpha-4: hsl(209, 92%, 19%, 0.04);
  --xds-color-dark-blue-dark: hsl(209, 93%, 11%);
  --xds-color-dark-blue-dark-alpha-20: hsl(209, 93%, 11%, 0.2);
  --xds-color-dark-blue-dark-alpha-4: hsl(209, 93%, 11%, 0.04);
  --xds-color-dark-blue-light: hsl(209, 39%, 35%);

  --xds-color-steel: hsl(199, 24%, 60%);
  --xds-color-steel-alpha-40: hsl(199, 24%, 60%, 0.4);
  --xds-color-steel-alpha-20: hsl(199, 24%, 60%, 0.2);
  --xds-color-steel-alpha-4: hsl(199, 24%, 60%, 0.04);
  --xds-color-steel-dark: hsl(199, 16%, 45%);
  --xds-color-steel-dark-alpha-20: hsl(199, 16%, 45%, 0.2);
  --xds-color-steel-dark-alpha-4: hsl(199, 16%, 45%, 0.04);
  --xds-color-steel-light: hsl(198, 37%, 73%);

  --xds-color-dark-steel: hsl(210, 19%, 33%);
  --xds-color-dark-steel-alpha-40: hsl(210, 19%, 33%, 0.4);
  --xds-color-dark-steel-alpha-20: hsl(210, 19%, 33%, 0.2);
  --xds-color-dark-steel-alpha-4: hsl(210, 19%, 33%, 0.04);
  --xds-color-dark-steel-dark: hsl(210, 19%, 21%);
  --xds-color-dark-steel-dark-alpha-20: hsl(210, 19%, 21%, 0.2);
  --xds-color-dark-steel-dark-alpha-4: hsl(210, 19%, 21%, 0.04);
  --xds-color-dark-steel-light: hsl(210, 19%, 47%);

  /**
        VIVID AND DYNAMIC
    */
  --xds-color-yellow: hsl(56, 100%, 50%);
  --xds-color-yellow-alpha-40: hsl(56, 100%, 50%, 0.4);
  --xds-color-yellow-alpha-20: hsl(56, 100%, 50%, 0.2);
  --xds-color-yellow-alpha-4: hsl(56, 100%, 50%, 0.04);
  --xds-color-yellow-dark: hsl(50, 100%, 50%);
  --xds-color-yellow-dark-alpha-20: hsl(50, 100%, 50%, 0.2);
  --xds-color-yellow-dark-alpha-4: hsl(50, 100%, 50%, 0.04);
  --xds-color-yellow-light: hsl(55, 100%, 75%);

  --xds-color-orange: hsl(31, 100%, 50%);
  --xds-color-orange-alpha-40: hsl(31, 100%, 50%, 0.4);
  --xds-color-orange-alpha-20: hsl(31, 100%, 50%, 0.2);
  --xds-color-orange-alpha-4: hsl(31, 100%, 50%, 0.04);
  --xds-color-orange-dark: hsl(26, 100%, 44%);
  --xds-color-orange-dark-alpha-20: hsl(26, 100%, 44%, 0.2);
  --xds-color-orange-dark-alpha-4: hsl(26, 100%, 44%, 0.04);
  --xds-color-orange-light: hsl(36, 99%, 59%);

  --xds-color-vibrant-orange: hsl(14, 100%, 50%);
  --xds-color-vibrant-orange-alpha-40: hsl(14, 100%, 50%, 0.4);
  --xds-color-vibrant-orange-alpha-20: hsl(14, 100%, 50%, 0.2);
  --xds-color-vibrant-orange-alpha-4: hsl(14, 100%, 50%, 0.04);
  --xds-color-vibrant-orange-dark: hsl(14, 97%, 43%);
  --xds-color-vibrant-orange-dark-alpha-20: hsl(14, 97%, 43%, 0.2);
  --xds-color-vibrant-orange-dark-alpha-4: hsl(14, 97%, 43%, 0.04);
  --xds-color-vibrant-orange-light: hsl(18, 100%, 61%);

  --xds-color-red: hsl(353, 100%, 44%);
  --xds-color-red-alpha-40: hsl(353, 100%, 44%, 0.4);
  --xds-color-red-alpha-20: hsl(353, 100%, 44%, 0.2);
  --xds-color-red-alpha-4: hsl(353, 100%, 44%, 0.04);
  --xds-color-red-dark: hsl(355, 84%, 39%);
  --xds-color-red-dark-alpha-20: hsl(355, 84%, 39%, 0.2);
  --xds-color-red-dark-alpha-4: hsl(355, 84%, 39%, 0.04);
  --xds-color-red-light: hsl(353, 80%, 67%);

  --xds-color-aubergine: hsl(310, 79%, 32%);
  --xds-color-aubergine-alpha-40: hsl(310, 79%, 32%, 0.4);
  --xds-color-aubergine-alpha-20: hsl(310, 79%, 32%, 0.2);
  --xds-color-aubergine-alpha-4: hsl(310, 79%, 32%, 0.04);
  --xds-color-aubergine-dark: hsl(300, 68%, 26%);
  --xds-color-aubergine-dark-alpha-20: hsl(300, 68%, 26%, 0.2);
  --xds-color-aubergine-dark-alpha-4: hsl(300, 68%, 26%, 0.04);
  --xds-color-aubergine-light: hsl(309, 38%, 59%);

  --xds-color-pink: hsl(309, 100%, 47%);
  --xds-color-pink-alpha-40: hsl(309, 100%, 47%, 0.4);
  --xds-color-pink-alpha-20: hsl(309, 100%, 47%, 0.2);
  --xds-color-pink-alpha-4: hsl(309, 100%, 47%, 0.04);
  --xds-color-pink-dark: hsl(309, 100%, 38%);
  --xds-color-pink-dark-alpha-20: hsl(309, 100%, 38%, 0.2);
  --xds-color-pink-dark-alpha-4: hsl(309, 100%, 38%, 0.04);
  --xds-color-pink-light: hsl(308, 90%, 74%);

  --xds-color-vibrant-green: hsl(147, 100%, 44%);
  --xds-color-vibrant-green-alpha-40: hsl(147, 100%, 44%, 0.4);
  --xds-color-vibrant-green-alpha-20: hsl(147, 100%, 44%, 0.2);
  --xds-color-vibrant-green-alpha-4: hsl(147, 100%, 44%, 0.04);
  --xds-color-vibrant-green-dark: hsl(147, 100%, 38%);
  --xds-color-vibrant-green-dark-alpha-20: hsl(147, 100%, 38%, 0.2);
  --xds-color-vibrant-green-dark-alpha-4: hsl(147, 100%, 38%, 0.04);
  --xds-color-vibrant-green-light: hsl(147, 80%, 69%);

  --xds-color-blue: hsl(216, 100%, 50%);
  --xds-color-blue-alpha-40: hsl(216, 100%, 50%, 0.4);
  --xds-color-blue-alpha-20: hsl(216, 100%, 50%, 0.2);
  --xds-color-blue-alpha-4: hsl(216, 100%, 50%, 0.04);
  --xds-color-blue-dark: hsl(216, 100%, 35%);
  --xds-color-blue-dark-alpha-20: hsl(216, 100%, 35%, 0.2);
  --xds-color-blue-dark-alpha-4: hsl(216, 100%, 35%, 0.04);
  --xds-color-blue-light: hsl(217, 100%, 67%);

  --xds-color-vibrant-blue: hsl(252, 100%, 49%);
  --xds-color-vibrant-blue-alpha-40: hsl(252, 100%, 49%, 0.4);
  --xds-color-vibrant-blue-alpha-20: hsl(252, 100%, 49%, 0.2);
  --xds-color-vibrant-blue-alpha-4: hsl(252, 100%, 49%, 0.04);
  --xds-color-vibrant-blue-dark: hsl(252, 100%, 35%);
  --xds-color-vibrant-blue-dark-alpha-20: hsl(252, 100%, 35%, 0.2);
  --xds-color-vibrant-blue-dark-alpha-4: hsl(252, 100%, 35%, 0.04);
  --xds-color-vibrant-blue-light: hsl(221, 97%, 59%);

  /**
      MAIN COLORS
  */

  --xds-color-midnight-blue: hsl(214, 54%, 13%);
  --xds-color-midnight-blue-40: hsl(214, 54%, 13%, 0.4);
  --xds-color-midnight-blue-20: hsl(214, 54%, 13%, 0.2);
  --xds-color-midnight-blue-4: hsl(214, 54%, 13%, 0.04);

  --xds-color-main-vibrant-blue-shade-4: hsl(227, 66%, 16%);
  --xds-color-main-vibrant-blue-shade-3: hsl(225, 60%, 23%);
  --xds-color-main-vibrant-blue-shade-2: hsl(225, 58%, 31%);
  --xds-color-main-vibrant-blue-shade-1: hsl(226, 55%, 39%);
  --xds-color-main-vibrant-blue: hsl(227, 57%, 46%);
  --xds-color-main-vibrant-blue-tint-4: hsl(225, 46%, 56%);
  --xds-color-main-vibrant-blue-tint-3: hsl(226, 47%, 68%);
  --xds-color-main-vibrant-blue-tint-2: hsl(225, 48%, 79%);
  --xds-color-main-vibrant-blue-tint-1: hsl(227, 51%, 90%);

  /**
      ACCENTS
  */

  --xds-color-accent-cyan-shade-4: hsl(192, 79%, 13%);
  --xds-color-accent-cyan-shade-3: hsl(193, 87%, 18%);
  --xds-color-accent-cyan-shade-2: hsl(192, 82%, 28%);
  --xds-color-accent-cyan-shade-1: hsl(193, 78%, 41%);
  --xds-color-accent-cyan: hsl(193, 76%, 48%);
  --xds-color-accent-cyan-tint-4: hsl(190, 71%, 60%);
  --xds-color-accent-cyan-tint-3: hsl(186, 70%, 72%);
  --xds-color-accent-cyan-tint-2: hsl(186, 68%, 82%);
  --xds-color-accent-cyan-tint-1: hsl(187, 71%, 93%);

  --xds-color-accent-teal-shade-4: hsl(180, 89%, 11%);
  --xds-color-accent-teal-shade-3: hsl(180, 85%, 16%);
  --xds-color-accent-teal-shade-2: hsl(180, 83%, 19%);
  --xds-color-accent-teal-shade-1: hsl(180, 100%, 21%);
  --xds-color-accent-teal: hsl(180, 100%, 25%);
  --xds-color-accent-teal-tint-4: hsl(179, 48%, 42%);
  --xds-color-accent-teal-tint-3: hsl(174, 38%, 62%);
  --xds-color-accent-teal-tint-2: hsl(165, 48%, 82%);
  --xds-color-accent-teal-tint-1: hsl(164, 49%, 91%);

  --xds-color-accent-green-shade-4: hsl(176, 81%, 18%);
  --xds-color-accent-green-shade-3: hsl(169, 70%, 26%);
  --xds-color-accent-green-shade-2: hsl(162, 65%, 35%);
  --xds-color-accent-green-shade-1: hsl(157, 59%, 44%);
  --xds-color-accent-green: hsl(146, 61%, 56%);
  --xds-color-accent-green-tint-4: hsl(145, 67%, 68%);
  --xds-color-accent-green-tint-3: hsl(127, 68%, 82%);
  --xds-color-accent-green-tint-2: hsl(110, 78%, 89%);
  --xds-color-accent-green-tint-1: hsl(107, 87%, 97%);

  --xds-color-accent-yellow-shade-4: hsl(48, 17%, 28%);
  --xds-color-accent-yellow-shade-3: hsl(60, 18%, 38%);
  --xds-color-accent-yellow-shade-2: hsl(52, 37%, 52%);
  --xds-color-accent-yellow-shade-1: hsl(54, 75%, 57%);
  --xds-color-accent-yellow: hsl(53, 99%, 65%);
  --xds-color-accent-yellow-tint-4: hsl(53, 98%, 74%);
  --xds-color-accent-yellow-tint-3: hsl(53, 100%, 82%);
  --xds-color-accent-yellow-tint-2: hsl(52, 100%, 89%);
  --xds-color-accent-yellow-tint-1: hsl(55, 100%, 95%);

  --xds-color-accent-orange-shade-4: hsl(338, 69%, 30%);
  --xds-color-accent-orange-shade-3: hsl(351, 62%, 38%);
  --xds-color-accent-orange-shade-2: hsl(1, 58%, 47%);
  --xds-color-accent-orange-shade-1: hsl(7, 68%, 51%);
  --xds-color-accent-orange: hsl(15, 100%, 56%);
  --xds-color-accent-orange-tint-4: hsl(12, 98%, 64%);
  --xds-color-accent-orange-tint-3: hsl(13, 97%, 75%);
  --xds-color-accent-orange-tint-2: hsl(14, 97%, 87%);
  --xds-color-accent-orange-tint-1: hsl(6, 71%, 94%);

  --xds-color-accent-purple-shade-4: hsl(261, 54%, 27%);
  --xds-color-accent-purple-shade-3: hsl(261, 52%, 37%);
  --xds-color-accent-purple-shade-2: hsl(262, 51%, 45%);
  --xds-color-accent-purple-shade-1: hsl(262, 60%, 54%);
  --xds-color-accent-purple: hsl(263, 100%, 65%);
  --xds-color-accent-purple-tint-4: hsl(262, 96%, 72%);
  --xds-color-accent-purple-tint-3: hsl(262, 96%, 82%);
  --xds-color-accent-purple-tint-2: hsl(263, 95%, 93%);
  --xds-color-accent-purple-tint-1: hsl(258, 100%, 97%);
}

:root {
  /**
    This is the default theme with cyan as its main color
    Primaries
  */

  --xds-color-primary: var(--xds-color-cyan);
  --xds-color-primary-40: var(--xds-color-cyan-alpha-40);
  --xds-color-primary-50: var(--xds-color-cyan-alpha-50);
  --xds-color-primary-20: var(--xds-color-cyan-alpha-20);
  --xds-color-primary-4: var(--xds-color-cyan-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-cyan-dark);
  --xds-color-primary-interaction-20: var(--xds-color-cyan-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-cyan-dark-alpha-4);

  /**
    TONES
  */
  --xds-tone-caution: var(--xds-color-orange);
  --xds-tone-critical: var(--xds-color-red);
  --xds-tone-positive: var(--xds-color-dark-green);
  --xds-tone-neutral: var(--xds-color-blue);

  --xds-tone-caution-dark: var(--xds-color-orange-light);
  --xds-tone-critical-dark: var(--xds-color-vibrant-orange);
  --xds-tone-positive-dark: var(--xds-color-dark-green-light);
  --xds-tone-neutral-dark: var(--xds-color-blue-light);

  /**
    Text Colors
  */
  --xds-color-text-primary: var(--xds-color-grey-dark);
  --xds-color-text-secondary: var(--xds-color-grey-2);
  --xds-color-text-label: var(--xds-color-grey-1);
  --xds-color-bg-global: var(--xds-color-grey-7);
  --xds-color-backdrop-modal: hsla(206, 8%, 17%, 0.4);
}

.xds\:d-none {
  display: none; /* TODO make this !important so we can be certain it overrides e.g. display: flex or grid or whatever */
}

.xds\:d-none {
  display: none;
}

.xds\:highlight-match {
  font-style: normal;
  background: var(--xds-color-primary-interaction-20);
  padding: 0;
}

/* use "xds:dropdown-item-content-stretch" for content of xds-dropdown-item if the content should stretch to fit the whole dropdown item, e.g. for links */
xds-dropdown-item:has(.xds\:dropdown-item-content-stretch) {
  position: relative;
}

xds-dropdown-item > .xds\:dropdown-item-content-stretch::before {
  content: '';
  position: absolute;
  inset: 0;
}

/**
* @prop --xds-tooltip-color: text color override for `<xds-text>` used in the content section of the tooltip
* @prop --xds-tooltip-background: color of the tooltip background
* @prop --xds-tooltip-arrow-size: size of the tooltip arrow
* @prop --xds-tooltip-border-radius: size of the tooltip border radius
* @prop --xds-tooltip-padding: padding of the tooltip area
* @prop --xds-tooltip-cursor: cursor for the default slot
* @prop --xds-tooltip-color-focus: color of the focus frame
*/

.tippy-box[data-animation=shift-toward-subtle][data-state=hidden]{opacity:0}.tippy-box[data-animation=shift-toward-subtle][data-state=hidden][data-placement^=top][data-state=hidden]{transform:translateY(-5px)}.tippy-box[data-animation=shift-toward-subtle][data-state=hidden][data-placement^=bottom][data-state=hidden]{transform:translateY(5px)}.tippy-box[data-animation=shift-toward-subtle][data-state=hidden][data-placement^=left][data-state=hidden]{transform:translateX(-5px)}.tippy-box[data-animation=shift-toward-subtle][data-state=hidden][data-placement^=right][data-state=hidden]{transform:translateX(5px)}

:host,
[data-tippy-root] {
  --xds-tooltip-color: var(--xds-color-4);
  --xds-tooltip-background: var(--xds-color-5);
  --xds-tooltip-arrow-size: 0.5rem;
  --xds-tooltip-border-radius: 0;
  --xds-tooltip-padding: var(--xds-spacing-03);
  --xds-tooltip-cursor: default;
  --xds-tooltip-color-focus: var(--xds-color-primary);

  display: inline-block;
}

:host([append-to='modal']) {
  position: relative;
}

/* Need to set the width to 100% for grid layouts */
[part='target'] {
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  background: transparent;
  cursor: var(--xds-tooltip-cursor);
}

[part='target']:focus {
  outline-style: solid;
  outline-offset: 2px;
  outline-color: var(--xds-tooltip-color-focus);
  outline-width: 2px;
}

/* Force the content of the default slot to 100% width */
::slotted(:not([slot])) {
  min-width: 100%;
}

.tooltip-content {
  display: none;
  padding: var(--xds-tooltip-padding);
  gap: var(--xds-spacing-05);
  max-width: 26.25rem;
  white-space: normal;
  text-align: initial;
}

.tooltip-content:focus {
  outline: 1px solid var(--xds-color-4);
  outline-offset: -4px;
}

[data-interactive] {
  --xds-tooltip-padding: var(--xds-spacing-05);
}

[data-tippy-root] .tooltip-content {
  display: grid;
}

/* tippy.js styles */
[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

:host([append-to='modal']) [data-tippy-root] {
  width: -moz-max-content;
  width: max-content;
  position: fixed !important;
}

.tippy-box[data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}

.tippy-box {
  --xds-color-text-primary: var(--xds-tooltip-color);

  cursor: default;
  position: relative;
  background-color: var(--xds-tooltip-background);
  border-radius: var(--xds-tooltip-border-radius);
  outline: 0;
  transition-property: transform, visibility, opacity;
}
.tippy-box[data-placement^='top'] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^='top'] > .tippy-arrow:before {
  bottom: calc(-1 * var(--xds-tooltip-arrow-size));
  left: 0;
  border-width: var(--xds-tooltip-arrow-size) var(--xds-tooltip-arrow-size) 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^='bottom'] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
  top: calc(-1 * var(--xds-tooltip-arrow-size));
  left: 0;
  border-width: 0 var(--xds-tooltip-arrow-size) var(--xds-tooltip-arrow-size);
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^='left'] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^='left'] > .tippy-arrow:before {
  border-width: var(--xds-tooltip-arrow-size) 0 var(--xds-tooltip-arrow-size) var(--xds-tooltip-arrow-size);
  border-left-color: initial;
  right: calc(-1 * var(--xds-tooltip-arrow-size));
  transform-origin: center left;
}
.tippy-box[data-placement^='right'] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^='right'] > .tippy-arrow:before {
  left: calc(-1 * var(--xds-tooltip-arrow-size));
  border-width: var(--xds-tooltip-arrow-size) var(--xds-tooltip-arrow-size) var(--xds-tooltip-arrow-size) 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state='visible'] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  width: calc(2 * var(--xds-tooltip-arrow-size));
  height: calc(2 * var(--xds-tooltip-arrow-size));
  color: var(--xds-tooltip-background);
}
.tippy-arrow:before {
  content: '';
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
.tippy-content {
  position: relative;
  z-index: 1;
  color: var(--xds-tooltip-color);
}


:root {
  /** --xds-time-immediate: 100ms;
  --xds-time-fast: 300ms;
  --xds-time-slower: 600ms;
  --xds-time-deliberate: 900ms; */

  /*
    Color "function"
    ----------------
    - each color has a -contrast version to ensure color contrast with color/background applicaitons
    - naming convention is
      --xds-color-{function...}[-contrast]

    Functions:
    ==========
    - default (<body>)
    - background
    - input-background
    - highlight (<mark>)
    - mute
    - action (<a>, <button>)
    - hover
    - visited
    - active
    - disabled
    - focus
    - selected

    https://seek-oss.github.io/braid-design-system/foundations/tones
    - tone-caution
    - tone-critical
    - tone-positive
    - tone-neutral
    - tone-info
    - tone-promote
  */

  /**

    Primaries

  */
  /*
  --xds-color-primary: var(--xds-color-cyan);
  --xds-color-primary-40: var(--xds-color-cyan-alpha-40);
  --xds-color-primary-20: var(--xds-color-cyan-alpha-20);
  --xds-color-primary-4: var(--xds-color-cyan-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-cyan-dark);
  --xds-color-primary-interaction-20: var(--xds-color-cyan-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-cyan-dark-alpha-4); */

  /**
      SIZES
    */
  --xds-ratio: 1.414;
  --xds-s-5: calc(var(--xds-s-4) / var(--xds-ratio));
  --xds-s-4: calc(var(--xds-s-3) / var(--xds-ratio));
  --xds-s-3: calc(var(--xds-s-2) / var(--xds-ratio));
  --xds-s-2: calc(var(--xds-s-1) / var(--xds-ratio));
  --xds-s-1: calc(var(--xds-s0) / var(--xds-ratio));
  --xds-s0: 1rem;
  --xds-s1: calc(var(--xds-s0) * var(--xds-ratio));
  --xds-s2: calc(var(--xds-s1) * var(--xds-ratio));
  --xds-s3: calc(var(--xds-s2) * var(--xds-ratio));
  --xds-s4: calc(var(--xds-s3) * var(--xds-ratio));
  --xds-s5: calc(var(--xds-s4) * var(--xds-ratio));

  --xds-s-header: auto;
  --xds-s-bottom-bar: 0rem;

  /**
   * TYPOGRAPHY
   */
  /* MANUAL SCALE */
  --xds-size-1: 0.625rem;
  --xds-size-2: 0.7rem;
  --xds-size-3: 0.75rem;
  --xds-size-4: 0.875rem;
  --xds-size-5: 1rem;
  --xds-size-6: 1.125rem;
  --xds-size-7: 1.25rem;
  --xds-size-8: 1.5rem;
  --xds-size-9: 1.75rem;
  --xds-size-10: 2rem;

  /* LEADING */
  --xds-leading-1: calc(18 / 10);
  --xds-leading-2: calc(17 / 11);
  --xds-leading-3: calc(18 / 12);
  --xds-leading-4: calc(24 / 14);
  --xds-leading-5: calc(22 / 16);
  --xds-leading-6: calc(26 / 18);
  --xds-leading-65: calc(24 / 18);
  --xds-leading-7: calc(24 / 20);
  --xds-leading-8: calc(28 / 24);
  --xds-leading-9: calc(32 / 28);

  /**
    AXIOM
    */
  --xds-measure: 60ch;

  --xds-weight-light: 200;
  --xds-weight-medium: 500;
  --xds-weight-bold: 700;
  --xds-weight-black: 900;

  --xds-leading-0: 0;

  --xds-tracking-0: 0;

  --xds-width-0: 0;

  --xds-timing-0: 0;

  --xds-body-L: 1.125rem;
  --xds-body-M: 1rem;
  --xds-body-S: 0.875rem;

  --xds-easing-out: cubic-bezier(0.175, 0.885, 0.36, 1);

  --xds-padding-ratio: 2.5;
  --xds-padding-l: 2rem;
  --xds-padding-m: 1.5rem;
  --xds-padding-s: 1rem;
  --xds-padding-xs: 0.75rem;
  --xds-padding-xxs: 0.5rem;
  --xds-padding-min: 0.25rem;
  --xds-button-medium-padding: var(--xds-padding-m) calc(var(--xds-padding-m) / var(--xds-padding-ratio));

  --xds-size-radiobutton: 0.875rem;
  --xds-color-sort-active: var(--xds-color-grey-dark);
  --xds-color-sort-idle: var(--xds-color-grey-light);

  /* layermodel */
  /* groups */
  --xds-z-index-extended: 20;
  --xds-z-index-overlay: 30;

  /* special elements */
  --xds-z-index-snackbar: 40;
  --xds-z-index-bottom-bar: 60;
  --xds-z-index-drawer: 65;
  --xds-z-index-input-search: 70;
  --xds-z-index-select: 70;
  --xds-z-index-header: 80;
  --xds-z-index-tooltip: 90;
  --xds-z-index-modal: 100;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

table {
  width: 100%;
}

html {
  font-size: 100%;
} /*16px*/

body {
  font-family: var(--xds-font-stack);
  font-weight: 400;
  line-height: 1.65;
  color: var(--xds-color-text-primary);
  scrollbar-color: var(--xds-color-grey-3) var(--xds-color-grey-6);
  scrollbar-width: 0.5rem;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--xds-color-grey-6);
}
::-webkit-scrollbar-thumb {
  background-color: var(--xds-color-grey-3);
}

.set-green-theme {
  --xds-color-primary: var(--xds-color-green);
  --xds-color-primary-40: var(--xds-color-green-alpha-40);
  --xds-color-primary-20: var(--xds-color-green-alpha-20);
  --xds-color-primary-4: var(--xds-color-green-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-green-dark);
  --xds-color-primary-interaction-20: var(--xds-color-green-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-green-dark-alpha-4);
}
.set-dark-green-theme {
  --xds-color-primary: var(--xds-color-dark-green);
  --xds-color-primary-40: var(--xds-color-dark-green-alpha-40);
  --xds-color-primary-20: var(--xds-color-dark-green-alpha-20);
  --xds-color-primary-4: var(--xds-color-dark-green-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-dark-green-dark);
  --xds-color-primary-interaction-20: var(--xds-color-dark-green-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-dark-green-dark-alpha-4);
}
.set-turquoise-theme {
  --xds-color-primary: var(--xds-color-turquoise);
  --xds-color-primary-40: var(--xds-color-turquoise-alpha-40);
  --xds-color-primary-20: var(--xds-color-turquoise-alpha-20);
  --xds-color-primary-4: var(--xds-color-turquoise-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-turquoise-dark);
  --xds-color-primary-interaction-20: var(--xds-color-turquoise-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-turquoise-dark-alpha-4);
}
.set-teal-theme {
  --xds-color-primary: var(--xds-color-teal);
  --xds-color-primary-40: var(--xds-color-teal-alpha-40);
  --xds-color-primary-20: var(--xds-color-teal-alpha-20);
  --xds-color-primary-4: var(--xds-color-teal-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-teal-dark);
  --xds-color-primary-interaction-20: var(--xds-color-teal-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-teal-dark-alpha-4);
}
.set-cyan-theme {
  --xds-color-primary: var(--xds-color-cyan);
  --xds-color-primary-40: var(--xds-color-cyan-alpha-40);
  --xds-color-primary-20: var(--xds-color-cyan-alpha-20);
  --xds-color-primary-4: var(--xds-color-cyan-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-cyan-dark);
  --xds-color-primary-interaction-20: var(--xds-color-cyan-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-cyan-dark-alpha-4);
}

.set-pale-blue-theme {
  --xds-color-primary: var(--xds-color-pale-blue);
  --xds-color-primary-40: var(--xds-color-pale-blue-alpha-40);
  --xds-color-primary-20: var(--xds-color-pale-blue-alpha-20);
  --xds-color-primary-4: var(--xds-color-pale-blue-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-pale-blue-dark);
  --xds-color-primary-interaction-20: var(--xds-color-pale-blue-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-pale-blue-dark-alpha-4);
}

.set-dark-blue-theme {
  --xds-color-primary: var(--xds-color-dark-blue);
  --xds-color-primary-40: var(--xds-color-dark-blue-alpha-40);
  --xds-color-primary-50: var(--xds-color-dark-blue-alpha-50);
  --xds-color-primary-20: var(--xds-color-dark-blue-alpha-20);
  --xds-color-primary-4: var(--xds-color-dark-blue-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-dark-blue-dark);
  --xds-color-primary-interaction-20: var(--xds-color-dark-blue-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-dark-blue-dark-alpha-4);
}

.set-dark-steel-theme {
  --xds-color-primary: var(--xds-color-dark-steel);
  --xds-color-primary-40: var(--xds-color-dark-steel-alpha-40);
  --xds-color-primary-20: var(--xds-color-dark-steel-alpha-20);
  --xds-color-primary-4: var(--xds-color-dark-steel-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-dark-steel-dark);
  --xds-color-primary-interaction-20: var(--xds-color-dark-steel-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-dark-steel-dark-alpha-4);
}
.set-steel-theme {
  --xds-color-primary: var(--xds-color-steel);
  --xds-color-primary-40: var(--xds-color-steel-alpha-40);
  --xds-color-primary-20: var(--xds-color-steel-alpha-20);
  --xds-color-primary-4: var(--xds-color-steel-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-steel-dark);
  --xds-color-primary-interaction-20: var(--xds-color-steel-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-steel-dark-alpha-4);
}
.set-yellow-theme {
  --xds-color-primary: var(--xds-color-yellow);
  --xds-color-primary-40: var(--xds-color-yellow-alpha-40);
  --xds-color-primary-20: var(--xds-color-yellow-alpha-20);
  --xds-color-primary-4: var(--xds-color-yellow-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-yellow-dark);
  --xds-color-primary-interaction-20: var(--xds-color-yellow-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-yellow-dark-alpha-4);
}
.set-orange-theme {
  --xds-color-primary: var(--xds-color-orange);
  --xds-color-primary-40: var(--xds-color-orange-alpha-40);
  --xds-color-primary-20: var(--xds-color-orange-alpha-20);
  --xds-color-primary-4: var(--xds-color-orange-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-orange-dark);
  --xds-color-primary-interaction-20: var(--xds-color-orange-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-orange-dark-alpha-4);
}
.set-vibrant-orange-theme {
  --xds-color-primary: var(--xds-color-vibrant-orange);
  --xds-color-primary-40: var(--xds-color-vibrant-orange-alpha-40);
  --xds-color-primary-20: var(--xds-color-vibrant-orange-alpha-20);
  --xds-color-primary-4: var(--xds-color-vibrant-orange-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-vibrant-orange-dark);
  --xds-color-primary-interaction-20: var(--xds-color-vibrant-orange-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-vibrant-orange-dark-alpha-4);
}
.set-red-theme {
  --xds-color-primary: var(--xds-color-red);
  --xds-color-primary-40: var(--xds-color-red-alpha-40);
  --xds-color-primary-20: var(--xds-color-red-alpha-20);
  --xds-color-primary-4: var(--xds-color-red-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-red-dark);
  --xds-color-primary-interaction-20: var(--xds-color-red-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-red-dark-alpha-4);
}
.set-aubergine-theme {
  --xds-color-primary: var(--xds-color-aubergine);
  --xds-color-primary-40: var(--xds-color-aubergine-alpha-40);
  --xds-color-primary-20: var(--xds-color-aubergine-alpha-20);
  --xds-color-primary-4: var(--xds-color-aubergine-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-aubergine-dark);
  --xds-color-primary-interaction-20: var(--xds-color-aubergine-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-aubergine-dark-alpha-4);
}
.set-pink-theme {
  --xds-color-primary: var(--xds-color-pink);
  --xds-color-primary-40: var(--xds-color-pink-alpha-40);
  --xds-color-primary-20: var(--xds-color-pink-alpha-20);
  --xds-color-primary-4: var(--xds-color-pink-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-pink-dark);
  --xds-color-primary-interaction-20: var(--xds-color-pink-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-pink-dark-alpha-4);
}
.set-vibrant-green-theme {
  --xds-color-primary: var(--xds-color-vibrant-green);
  --xds-color-primary-40: var(--xds-color-vibrant-green-alpha-40);
  --xds-color-primary-20: var(--xds-color-vibrant-green-alpha-20);
  --xds-color-primary-4: var(--xds-color-vibrant-green-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-vibrant-green-dark);
  --xds-color-primary-interaction-20: var(--xds-color-vibrant-green-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-vibrant-green-dark-alpha-4);
}
.set-blue-theme {
  --xds-color-primary: var(--xds-color-blue);
  --xds-color-primary-40: var(--xds-color-blue-alpha-40);
  --xds-color-primary-20: var(--xds-color-blue-alpha-20);
  --xds-color-primary-4: var(--xds-color-blue-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-blue-dark);
  --xds-color-primary-interaction-20: var(--xds-color-blue-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-blue-dark-alpha-4);
}
.set-vibrant-blue-theme {
  --xds-color-primary: var(--xds-color-vibrant-blue);
  --xds-color-primary-40: var(--xds-color-vibrant-blue-alpha-40);
  --xds-color-primary-20: var(--xds-color-vibrant-blue-alpha-20);
  --xds-color-primary-4: var(--xds-color-vibrant-blue-alpha-4);
  --xds-color-primary-interaction: var(--xds-color-vibrant-blue-dark);
  --xds-color-primary-interaction-20: var(--xds-color-vibrant-blue-dark-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-vibrant-blue-dark-alpha-4);
}

.set-midnight-blue-theme {
  --xds-color-vibrant-blue-shade-4: hsl(225deg, 46%, 56%);
  --xds-color-vibrant-blue-shade-4-alpha-20: hsla(225deg, 46%, 56%, 20%);
  --xds-color-vibrant-blue-shade-2: #21377c;
  --xds-color-vibrant-blue-shade-2-alpha-20: hsla(225deg, 58%, 31%, 20%);
  --xds-color-vibrant-blue-shade-2-alpha-4: hsla(225deg, 58%, 31%, 4%);
  --xds-color-0: hsl(206, 8%, 17%);
  --xds-color-1: #175787;
  --xds-color-2: #6d767c;
  --xds-color-3: #bcbfc1;
  --xds-color-4: #ffffff;
  --xds-color-5: #233241;
  --xds-color-grey-dark: var(--xds-color-0);
  --xds-color-grey-dark-alpha-4: hsla(206, 8%, 17%, 4%);
  --xds-color-grey-light: var(--xds-color-3);
  --xds-color-grey-1: var(--xds-color-2);
  --xds-color-grey-1-alpha-4: #6e777c0a;
  --xds-color-grey-2: #9fa4a8;
  --xds-color-grey-3: #bcbfc1;
  --xds-color-grey-4: #d9dadc;
  --xds-color-grey-5: #f4f4f4;
  --xds-color-grey-6: #f4f4f4;
  --xds-color-grey-6-alpha-15: hsla(0, 0%, 96%, 15%);
  --xds-color-grey-7: #f4f4f4;
  --xds-color-primary: var(--xds-color-midnight-blue);
  --xds-color-primary-40: var(--xds-color-midnight-blue-40);
  --xds-color-primary-20: var(--xds-color-midnight-blue-20);
  --xds-color-primary-4: var(--xds-color-midnight-blue-4);
  --xds-color-primary-interaction: var(--xds-color-vibrant-blue-shade-2);
  --xds-color-primary-interaction-20: var(--xds-color-vibrant-blue-shade-2-alpha-20);
  --xds-color-primary-interaction-4: var(--xds-color-vibrant-blue-shade-2-alpha-4);
  --xds-color-orange: hsl(15deg, 100%, 56%);
  --xds-color-orange-alpha-20: hsla(15deg, 100%, 56%, 20%);
  --xds-color-red: hsl(353deg, 100%, 44%);
  --xds-text-color: var(--xds-color-primary);
  --xds-color-text-primary: var(--xds-color-primary);

  xds-header {
    --xds-header-brand-bg-color: var(--xds-color-main-vibrant-blue-shade-1);
    --xds-header-main-bg-color: var(--xds-color-midnight-blue) !important;
  }
}
